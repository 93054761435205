import React from "react";
import { navigate } from "gatsby";
import cn from "classnames";

export default function Button({ children = "Napisz do mnie" }) {
  const handleOnClick = () => {
    navigate("#kontakt");
  };

  return (
    <button
      onClick={handleOnClick}
      className="bg-highlighted rounded-full text-lg leading-8 font-bold hover:bg-opacity-80 transition-all px-12 py-4"
    >
      {children}
    </button>
  );
}

export const FormButton = ({ disabled, children }) => {
  return (
    <button
      className={cn(
        "bg-highlighted rounded-md font-bold text-xl p-6 transition-colors duration-500",
        {
          "bg-highlighted": !disabled,
          "bg-separator": disabled,
        }
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const LinkButton = (props) => {
  return (
    <button
      {...props}
      className="text-highlighted text-xl font-bold text-left hover:underline"
    />
  );
};

export const SmallButton = (props) => {
  return (
    <button
      {...props}
      className="bg-highlighted text-white inline-block p-3 md:px-5 md:py-3 rounded-md text-base md:text-lg uppercase font-semibold"
    />
  );
};
