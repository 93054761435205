import React from "react";
import { FullWidthContainer, ContentContainer } from "../components/Container";
import { SectionTitle } from "../components/SectionTitle";
import {
  TraningProgrammesCard,
  PersonalTrainingCard,
  FirstAppointmentCard,
  OnlineConsultationCard,
} from "../components/ServiceCard";
import bgImage from "../images/bg-offer-compressed.jpg";
import content from "../content/sections/services.json";

export default function Services() {
  return (
    <FullWidthContainer bgColor="black" id="uslugi">
      <ContentContainer>
        <SectionTitle title={content.title} />
      </ContentContainer>
      <div
        className="py-14 xl:py-20 bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-80" />
        <div className="px-5 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-12 2xl:gap-8 2xl:px-10 max-w-[2000px] mx-auto justify-items-center">
            <FirstAppointmentCard />
            <PersonalTrainingCard />
            <TraningProgrammesCard />
            <OnlineConsultationCard />
          </div>
        </div>
      </div>
    </FullWidthContainer>
  );
}
