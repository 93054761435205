import React, { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FullWidthContainer, ContentContainer } from "../components/Container";
import { SectionTitle } from "../components/SectionTitle";
import QuoteIcon from "../images/icons/quote.svg";
import cn from "classnames";
import content from "../content/sections/rules.json";
import { useOnScreen } from "../hooks/useOnScreen";
import { FizjonersReferal } from "../components/FizjonersToast";

const QuoteSection = ({ quote, left, right }) => (
  <div
    className={cn(
      "flex flex-col justify-center h-full w-full text-left max-w-screen-sm my-8 lg:my-2",
      {
        "lg:pl-24": right,
        "lg:pr-24": left,
      }
    )}
  >
    <QuoteIcon className="mb-5" />
    <h3 className="text-2xl lg:text-3xl font-bold">{quote.quote}</h3>
    <span className="inline-block text-lg my-10">{quote.desc}</span>
  </div>
);

const Row = ({ children, reverse }) => {
  return (
    <div
      className={cn("flex", {
        "flex-col-reverse lg:flex-row": reverse,
        "flex-col lg:flex-row": !reverse,
      })}
    >
      {Array.from(children).map((child, idx) => (
        <div key={idx} className="flex-1">
          {child}
        </div>
      ))}
    </div>
  );
};

export default function RulesSection() {
  const [alreadyShown, setAlreadyShown] = useState(false);
  const [show, setShow] = useState(false);
  const containerRef = useRef();

  const isOnScreen = useOnScreen(containerRef);

  useEffect(() => {
    if (isOnScreen && !alreadyShown) {
      setShow(true);
      setAlreadyShown(true);
    }
  }, [isOnScreen, alreadyShown]);

  return (
    <>
      <FizjonersReferal active={show} onClose={() => setShow(false)} />
      <FullWidthContainer bgColor="black" ref={containerRef}>
        <ContentContainer>
          <SectionTitle title={content.title} />
          <div className="pb-16 xl:pb-52 xl:px-36">
            <Row>
              <StaticImage
                quality={90}
                placeholder="blurred"
                src="../images/rules-1.jpg"
                alt="Traning 1"
                className="w-full"
              />
              <QuoteSection quote={content.quotes[0]} right className="" />
            </Row>
            <Row reverse>
              <QuoteSection quote={content.quotes[1]} left />
              <StaticImage
                quality={90}
                placeholder="blurred"
                src="../images/rules-2.jpg"
                alt="Traning 2"
                className="w-full"
              />
            </Row>
            <Row>
              <StaticImage
                quality={90}
                placeholder="blurred"
                src="../images/rules-3.jpg"
                alt="Traning 3"
                className="w-full"
              />
              <QuoteSection quote={content.quotes[2]} right />
            </Row>
            <Row reverse>
              <QuoteSection quote={content.quotes[3]} left />
              <StaticImage
                quality={90}
                placeholder="blurred"
                src="../images/rules-4.jpg"
                alt="Traning 4"
                className="w-full"
              />
            </Row>
            <Row>
              <StaticImage
                quality={90}
                placeholder="blurred"
                src="../images/rules-5.jpg"
                alt="Traning 5"
                className="w-full"
              />
              <QuoteSection quote={content.quotes[4]} right />
            </Row>
          </div>
        </ContentContainer>
      </FullWidthContainer>
    </>
  );
}
