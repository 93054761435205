import React, { useEffect, useState } from "react";
import { MenuItem } from "./MenuItem";
import MenuIcon from "../images/icons/menu.svg";
import CloseIcon from "../images/icons/close.svg";
import { menuItems } from "../config/menu";
import { useBreakpointChange } from "../hooks/useWindowSize";
import { FullWidthContainer, ContentContainer } from "./Container";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export const Menu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isDesktop = useBreakpointChange();

  useEffect(() => {
    if (isMobileMenuOpen && isDesktop) {
      setIsMobileMenuOpen(false);
    }
  }, [isDesktop, isMobileMenuOpen]);

  return (
    <div className="fixed top-0 left-0 w-screen z-20">
      <FullWidthContainer bgColor="black">
        <ContentContainer>
          <DekstopMenuBar />
          <MobileMenuBar setIsMobileMenuOpen={setIsMobileMenuOpen} />
        </ContentContainer>
      </FullWidthContainer>
      {isMobileMenuOpen && (
        <div className="fixed inset-0 w-screen h-screen z-30 p-8 bg-black bg-opacity-95 flex flex-col">
          <div className="flex flex-col items-center space-y-8 mt-8">
            {menuItems.map((item) => (
              <MenuItem
                {...item}
                key={item.path}
                onClickCb={() => setIsMobileMenuOpen(false)}
                mobile
              />
            ))}
          </div>
          <div className="text-center w-full mt-12">
            <span className="inline-block border-2 border-white p-2 rounded-full">
              <CloseIcon
                className="w-8 h-8 mx-auto"
                onClick={() => setIsMobileMenuOpen(false)}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const MobileMenuBar = ({ setIsMobileMenuOpen }) => {
  return (
    <nav className="flex lg:hidden py-6 items-center justify-between">
      <Link to="/" className="inline-block">
        <StaticImage
          src="../images/logo.png"
          alt="logo"
          qualiy={100}
          className="w-8 h-8 object-cover object-center"
          placeholder="none"
        />
      </Link>
      <MenuIcon className="w-8 h-8" onClick={() => setIsMobileMenuOpen(true)} />
    </nav>
  );
};

const DekstopMenuBar = () => {
  return (
    <nav className="hidden lg:flex py-10 items-center space-x-14">
      {menuItems.map((item) => (
        <MenuItem {...item} key={item.path} />
      ))}
    </nav>
  );
};
