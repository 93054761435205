import React, { forwardRef } from "react";
import cn from "classnames";

const containers = {
  section: "section",
  header: "header",
  footer: "footer",
  default: "div",
};

export default function Container({ children, className, type, id }) {
  const Container = containers[type] || containers.section;
  return (
    <Container className={cn("min-w-full", className)} id={id}>
      <div className="max-w-screen-2xl mx-auto">{children}</div>
    </Container>
  );
}

export const FullWidthContainer = forwardRef(
  ({ children, bgColor, id }, ref) => {
    return (
      <div
        className={cn("w-screen max-w-full", {
          "bg-highlighted": bgColor === "highlighted",
          "bg-boxGray": bgColor === "boxGray",
          "bg-black": bgColor === "black",
          "bg-darkGray": bgColor === "darkGray",
        })}
        ref={ref}
        id={id}
      >
        {children}
      </div>
    );
  }
);

export const ContentContainer2 = ({ children, fullHeight }) => {
  return (
    <div
      className={cn("max-w-screen-2xl mx-auto px-5 md:px-8", {
        "h-full": fullHeight,
      })}
    >
      {children}
    </div>
  );
};

export const ContentContainer = ContentContainer2;
