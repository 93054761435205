import React from "react";
import { FullWidthContainer, ContentContainer } from "../components/Container";
import Button from "../components/Button";
import { Heading } from "../components/Heading";
import PreparationStep from "../components/PreparationStep";
import { StaticImage } from "gatsby-plugin-image";
import content from "../content/sections/first-appointment.json";

const Bar = () => (
  <span
    className="inline-block bg-highlighted w-1 rounded"
    style={{ height: 35 }}
  />
);

const StepContent = ({ children, number }) => {
  return (
    <div className="flex items-center tracking-tight">
      <Bar />
      <span className="text-4xl ml-5">{`0${number}`}</span>
      <span className="text-xl md:text-2xl ml-6 md:ml-12">{children}</span>
    </div>
  );
};

export default function FirstAppointment() {
  return (
    <>
      <FullWidthContainer bgColor="black" id="pierwsze-spotkanie">
        <ContentContainer>
          <div className="pt-16 pb-28 lg:pt-40 xl:pt-56">
            <Heading size={3}>{content.title}</Heading>
            <div className="mt-8 md:mt-16 grid grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-10">
              {content.steps.map((step, index) => (
                <StepContent key={step.title} number={index + 1}>
                  {step.title}
                </StepContent>
              ))}
            </div>
            <div className="mt-12 md:mt-24">
              <Button />
            </div>
          </div>
          <div className="flex justify-center">
            <StaticImage
              src="../images/arrow-bottom-gray.png"
              alt="arrow"
              quality={50}
            />
          </div>
        </ContentContainer>
      </FullWidthContainer>
      <FullWidthContainer bgColor="darkGray" id="metody-pracy">
        <ContentContainer>
          <div className="py-12 md:py-24 lg:px-24 grid grid-cols-1 md:grid-cols-2 md:gap-x-20 xl:gap-x-32 gap-y-14">
            {content.steps.map((step, idx) => (
              <PreparationStep
                title={step.titleShort}
                content={step.desc}
                number={`0${idx + 1}`}
                key={step.titleShort}
              />
            ))}
          </div>
        </ContentContainer>
      </FullWidthContainer>
    </>
  );
}
