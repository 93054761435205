import React from "react";
import { ContentContainer, FullWidthContainer } from "../components/Container";
import { LinkButton } from "../components/Button";
import PhoneIcon from "../images/icons/phone.svg";
import PinIcon from "../images/icons/pin.svg";
import ArrowRightIcon from "../images/icons/arrow-right.svg";
import { Heading } from "../components/Heading";
import contactData from "../content/contact/contact.json";
import { NetlifyForm } from "../components/Form";

const content = {
  title: "Cześć! Jeśli masz jakieś pytania, napisz do mnie:",
};

export default function ConctactForm() {
  return (
    <FullWidthContainer bgColor="black" id="kontakt">
      <ContentContainer>
        <div className="flex flex-col-reverse space-y-reverse space-y-20 lg:space-y-0 lg:flex-row lg:justify-center lg:items-center lg:space-x-12 py-20">
          <div className="flex flex-col space-y-14 flex-1">
            <div className="flex items-start space-x-10">
              <PhoneIcon />
              <div className="flex flex-col">
                <strong className="text-2xl mb-2">Telefon</strong>
                <span className="text-xl">{contactData.phone}</span>
              </div>
            </div>
            <div className="flex items-start space-x-10">
              <PinIcon />
              <div className="flex flex-col">
                <strong className="text-2xl mb-2">Adres</strong>
                <span className="text-xl">{contactData.address.line1}</span>
                <span className="text-xl">{contactData.address.line2}</span>
                <span className="text-xl mb-3">
                  {contactData.address.line3}
                </span>
                <LinkButton
                  onClick={() =>
                    window.open(contactData.address.mapLink, "_blank")
                  }
                >
                  <span className="mr-2">Sprawdź na mapie</span>
                  <ArrowRightIcon className="inline-block" />
                </LinkButton>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1">
            <div className="max-w-screen-sm mb-4">
              <Heading size={3}>{content.title}</Heading>
            </div>
            <NetlifyForm />
          </div>
        </div>
      </ContentContainer>
    </FullWidthContainer>
  );
}
