import React from "react";
import FaceIcon from "../images/icons/face.svg";
import cn from "classnames";

export default function TestimonialBox({ content, author, highlighted }) {
  return (
    <div
      className={cn("p-9 flex flex-col items-start shadow-md rounded-2xl", {
        "bg-boxGray": !highlighted,
        "bg-highlighted": highlighted,
      })}
    >
      <div
        className={cn("rounded-full p-4", {
          "bg-highlighted text-white": !highlighted,
          "bg-white text-highlighted": highlighted,
        })}
      >
        <FaceIcon className="w-5 h-5 fill-current" alt="icon" />
      </div>
      <span className="mt-4 text-lg lg:leading-8">{content}</span>
      <span className="mt-6 font-bold">{author}</span>
    </div>
  );
}
