import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { SmallButton } from "./Button";
import MessageIcon from "../images/icons/message.svg";
import content from "../content/modal/modal.json";

export const Modal = () => {
  const [show, setShow] = useState(content.enabled);
  const onClose = () => setShow(false);

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-90"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block p-5 md:p-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm w-full max-w-screen-md overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">
              <div className="text-center">
                <MessageIcon className="inline-block my-4 w-12 h-12" />
              </div>
              <h3 className="text-white font-bold w-full text-2xl text-center uppercase mb-6">
                {content.title}
              </h3>
              <p className="text-base md:text-lg md:px-8 mt-6">
                {content.message}
              </p>
              <div className="mt-12 text-right">
                <SmallButton onClick={() => onClose()}>Zamknij</SmallButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
