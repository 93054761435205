import React from "react";

export const Heading = ({ size = 1, children }) => {
  switch (size) {
    case 3:
      return (
        <h3 className="text-4xl leading-tight font-bold tracking-tight">
          {children}
        </h3>
      );
    case 4:
      return (
        <h4 className="md:text-3xl leading-tight font-bold tracking-tight">
          {children}
        </h4>
      );
    default:
      return null;
  }
};
