import { useLayoutEffect, useState } from "react";
import throttle from "lodash.throttle";

const MD_BREAKPOINT = 768;

export const useBreakpointChange = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useLayoutEffect(() => {
    const handleWindowResize = throttle((event) => {
      setIsDesktop(event.target.innerWidth >= MD_BREAKPOINT);
    }, 300);

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return isDesktop;
};
