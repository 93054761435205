import React from "react";
import { ContentContainer, FullWidthContainer } from "../components/Container";
import { MenuItem } from "../components/MenuItem";
import { menuItems } from "../config/menu";
import ytIcon from "../images/icons/youtube.svg";
import fbIcon from "../images/icons/facebook.svg";
import instagramIcon from "../images/icons/instagram.svg";
import content from "../content/contact/social_media.json";

const socialIcons = [
  { Icon: fbIcon, path: content.facebook, name: "Facebook" },
  { Icon: ytIcon, path: content.youtube, name: "YouTube" },
  { Icon: instagramIcon, path: content.instagram, name: "Instagram" },
];

export default function Footer() {
  return (
    <FullWidthContainer bgColor="boxGray">
      <ContentContainer>
        <div className="pt-12 lg:pt-32 pb-12 lg:pb-16">
          <div className="flex flex-col items-start space-y-6 lg:space-y-0 lg:flex-row lg:items-center lg:justify-start lg:space-x-14">
            {menuItems.map((item) => (
              <MenuItem key={item.path} {...item} />
            ))}
          </div>
          <hr className="mt-16 lg:mt-28 text-separator border-t-2" />
          <div className="mt-9 flex items-center justify-end space-x-8">
            {socialIcons.map(({ Icon, path, name }) => (
              <a
                href={path}
                key={path}
                rel="noreferrer"
                target="_blank"
                title={name}
              >
                <Icon />
              </a>
            ))}
          </div>
        </div>
      </ContentContainer>
      <FullWidthContainer bgColor="highlighted">
        <ContentContainer>
          <div className="py-8 text-center text-lg font-semibold">
            {`Copyright ${new Date().getFullYear()}. All rights reserved.`}
          </div>
        </ContentContainer>
      </FullWidthContainer>
    </FullWidthContainer>
  );
}
