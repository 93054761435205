import React from "react";
import { ToastContainer as BaseContainer, toast } from "react-toastify";
import TickIcon from "../images/icons/check.svg";

export const ToastContainer = () => {
  return (
    <BaseContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  );
};

export const toastSuccess = (content) =>
  toast.success(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: <TickIcon className="w-6 h-6" />,
  });

export const toastError = (content) =>
  toast.error(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
