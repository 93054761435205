import React, { useRef, useState } from "react";
import { FormButton } from "./Button";
import EnvelopeIcon from "../images/icons/envelope.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { toastError, toastSuccess } from "../components/Toast";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const initialFormData = {
  name: "",
  email: "",
  message: "",
  "g-recaptcha-response": "",
};

export const NetlifyForm = () => {
  const [formData, setFormData] = useState(initialFormData);
  const recaptchaRef = useRef();

  const handleOnSubmit = (evt) => {
    evt.preventDefault();

    const body = encode({
      "form-name": evt.target.getAttribute("name"),
      ...formData,
    });

    fetch("/", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => {
        recaptchaRef.current.reset();
        setFormData(initialFormData);
        toastSuccess("Wiadomość została wysłana!");
      })
      .catch(() => {
        toastError(
          "Wystąpił błąd przy wysyłaniu formularza. Proszę spróbować ponownie"
        );
        recaptchaRef.current.reset();
      });
  };

  const isInputValid = Object.entries(formData).every(
    ([key, value]) => !!(value && value.trim())
  );

  return (
    <form
      name="contact"
      data-netlify="true"
      data-netlify-recaptcha="true"
      className="flex flex-col items-start space-y-5 lg:space-y-7"
      onSubmit={handleOnSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <input
        type="text"
        name="name"
        placeholder="Imię"
        className="w-72"
        onChange={(evt) => setFormData({ ...formData, name: evt.target.value })}
        value={formData.name}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        className="w-72"
        onChange={(evt) =>
          setFormData({ ...formData, email: evt.target.value })
        }
        value={formData.email}
      />
      <textarea
        name="message"
        placeholder="Napisz wiadomość..."
        className="w-full h-36 "
        onChange={(evt) =>
          setFormData({ ...formData, message: evt.target.value })
        }
        value={formData.message}
      />
      <ReCAPTCHA
        sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        theme="dark"
        ref={recaptchaRef}
        onChange={(value) =>
          setFormData({ ...formData, "g-recaptcha-response": value })
        }
      />
      <div className="pt-6">
        <FormButton type="submit" disabled={!isInputValid}>
          <div className="flex items-center justify-center">
            <EnvelopeIcon />
            <span className="ml-4">Wyślij wiadomość</span>
          </div>
        </FormButton>
      </div>
    </form>
  );
};
