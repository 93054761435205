import React from "react";
import { FullWidthContainer, ContentContainer } from "../components/Container";
import Button from "../components/Button";
import content from "../content/sections/hero.json";
import { StaticImage } from "gatsby-plugin-image";

export default function HeroSection() {
  return (
    <FullWidthContainer className="bg-black max-h-screen">
      <ContentContainer>
        <div className="flex flex-col justify-center items-start relative lg:pt-14 lg:pb-40 xl:pt-44 xl:pb-72">
          <div className="max-w-sm md:max-w-full py-12">
            <h1 className="font-bold tracking-tight uppercase leading-none text-4xl md:text-6xl lg:max-w-2xl">
              {content.title}
            </h1>
            <div className="mt-12 md:mt-14">
              <Button />
            </div>
          </div>
          <div className="mt-6 lg:mt-0 mx-auto lg:absolute lg:bottom-0 lg:right-0 max-w-xs md:max-w-sm lg:max-w-md xl:max-w-full">
            <StaticImage
              className="object-center object-cover"
              src="../images/profile.png"
              alt="Zdjęcie profilowe - Krzysztof Klempka"
              quality={95}
            />
          </div>
          <div className="hidden lg:block lg:absolute lg:bottom-0 lg:left-0 py-12">
            <span className="text-base">{content.hashtag}</span>
          </div>
          <div className="hidden lg:flex w-full justify-center absolute bottom-0">
            <StaticImage
              className="animate-bounce"
              src="../images/arrow-bottom-gray.png"
              alt="arrow bottom"
              width={50}
            />
          </div>
        </div>
      </ContentContainer>
    </FullWidthContainer>
  );
}
