import React from "react";
import { FullWidthContainer, ContentContainer } from "../components/Container";
import TestimonialBox from "../components/TestimonialBox";
import { SectionTitle } from "../components/SectionTitle";
import content from "../content/sections/testimonials.json";
import { Swiper, SwiperSlide } from "swiper/react";

export default function TestimonialsSection() {
  return (
    <FullWidthContainer bgColor="darkGray" id="opinie">
      <ContentContainer>
        <SectionTitle title={content.title} />
        <div className="pb-16 lg:pb-32">
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 32,
              },
              1024: {
                spaceBetween: 32,
                slidesPerView: 3,
              },
            }}
            pagination={{
              bulletActiveClass: "opacity-100",
              bulletClass:
                "w-3 h-3 mx-1 inline-block rounded-full opacity-20 bg-white",
              clickable: true,
              clickableClass: "cursor-pointer",
            }}
            className="pb-16"
            cssMode
          >
            {content.testimonials.map((testimonial, index) => (
              <SwiperSlide key={testimonial.author}>
                <TestimonialBox
                  {...testimonial}
                  highlighted={(index + 1) % 3 === 0}
                  key={testimonial.author}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </ContentContainer>
    </FullWidthContainer>
  );
}
