import React from "react";
import cn from "classnames";
import TickIcon from "../images/icons/check.svg";
import content from "../content/sections/services.json";

const MainConatiner = ({ children }) => {
  return (
    <div className="flex flex-col max-w-md bg-white bg-opacity-10 rounded-2xl backdrop-filter backdrop-blur-xl px-9 flex-1">
      {children}
    </div>
  );
};

const MainPrice = ({ price, oldPrice, promoLabel }) => {
  return (
    <div className="relative flex flex-col">
      {oldPrice && (
        <span className="text-highlighted text-3xl line-through absolute left-0 -top-7">
          {oldPrice}zł
        </span>
      )}
      {promoLabel && (
        <div className="absolute -bottom-6 left-0">
          <PromoLabel label={promoLabel} />
        </div>
      )}
      <span className="text-highlighted text-6xl">{price}zł</span>
    </div>
  );
};

const Header = ({ children }) => {
  return (
    <h4 className="text-2xl md:text-2xl leading-tight font-bold tracking-tight uppercase text-center">
      {children}
    </h4>
  );
};

const BottomContainer = ({ children }) => {
  return (
    <div className="py-8 flex flex-col space-y-5 lg:min-h-cardBottom">
      {children}
    </div>
  );
};

const MiddleContainer = ({ children, empty }) => {
  return (
    <div
      className={cn("py-8 lg:min-h-cardMiddle", {
        "hidden lg:block": empty,
      })}
    >
      {children}
    </div>
  );
};

const TopContainer = ({ children }) => {
  return (
    <div className="flex flex-col items-center space-y-8 py-8 md:py-14">
      {children}
    </div>
  );
};

const ListItem = ({ children }) => {
  return (
    <div className="flex items-start">
      <TickIcon className="flex-shrink-0" />
      <span className="inline-block ml-3 md:ml-5 tracking-tight">
        {children}
      </span>
    </div>
  );
};

const PromoLabel = ({ label }) => {
  return (
    <div className="bg-highlighted p-1 rounded-sm text-xs font-bold inline-block relative">
      {typeof label === "string" ? label.toUpperCase() : ""}
    </div>
  );
};

const Deal = ({ name, price, info, topOffer, label }) => {
  return (
    <div>
      {topOffer && <PromoLabel label={label} />}
      <div className="text-2xl leading-tight">
        <span>
          {name}
          {" - "}
        </span>
        <span className="text-highlighted font-bold">{price}zł</span>
        <span className="block md:inline-block text-xs md:ml-3 uppercase font-semibold">
          ({info})
        </span>
      </div>
    </div>
  );
};

export const PersonalTrainingCard = () => {
  return (
    <MainConatiner>
      <div className="flex flex-col divide-y divide-gray">
        <TopContainer>
          <Header>{content.personal_training.title}</Header>
          <MainPrice price={content.personal_training.price} />
        </TopContainer>
        <MiddleContainer>
          <span className="inline-block uppercase text-2xl text-center w-full">
            W pakiecie taniej!
          </span>
          <div className="pt-6 space-y-4">
            {content.personal_training.packages.map((packgeOffer) => (
              <Deal
                info={packgeOffer.info}
                price={packgeOffer.price}
                name={packgeOffer.desc}
                topOffer={packgeOffer.specialOffer}
                key={packgeOffer.desc}
                label="najszczęściej wybierany"
              />
            ))}
          </div>
        </MiddleContainer>
        <BottomContainer>
          {content.personal_training.bullets.map((item, idx) => (
            <ListItem key={idx}>{item}</ListItem>
          ))}
        </BottomContainer>
      </div>
    </MainConatiner>
  );
};

export const TraningProgrammesCard = () => {
  return (
    <MainConatiner>
      <div className="flex flex-col divide-y divide-gray">
        <TopContainer>
          <Header>{content.training_programmes.title}</Header>
          <div className="relative inline-block">
            <MainPrice price={content.training_programmes.price} />
            <span className="absolute top-14 left-1 text-base whitespace-nowrap">
              {content.training_programmes.titleInfo}
            </span>
          </div>
        </TopContainer>
        <MiddleContainer>
          <div className="text-xl lg:text-2xl flex flex-col space-y-8 lg:mt-12">
            <span>
              pierwszy miesiąc -{" "}
              <strong className="text-highlighted">
                {content.training_programmes.offersDetails.firstMonthPrice} zł
              </strong>
            </span>
            <span>
              od drugiego miesiąca -{" "}
              <strong className="text-highlighted">
                {content.training_programmes.offersDetails.price} zł
              </strong>
            </span>
          </div>
        </MiddleContainer>
        <BottomContainer>
          {content.training_programmes.bullets.map((item) => (
            <ListItem key={item}>{item}</ListItem>
          ))}
        </BottomContainer>
      </div>
    </MainConatiner>
  );
};

export const FirstAppointmentCard = () => {
  return (
    <MainConatiner>
      <div className="flex flex-col divide-y divide-gray">
        <TopContainer>
          <Header>{content.first_appointment.title}</Header>
          <MainPrice price={content.first_appointment.price} />
        </TopContainer>
        <MiddleContainer empty />
        <BottomContainer>
          {content.first_appointment.bullets.map((item, idx) => (
            <ListItem key={idx}>{item}</ListItem>
          ))}
        </BottomContainer>
      </div>
    </MainConatiner>
  );
};

export const OnlineConsultationCard = () => {
  const { price } = content.online_consulatation.offersDetails;

  return (
    <MainConatiner>
      <div className="flex flex-col divide-y divide-gray">
        <TopContainer>
          <Header>{content.online_consulatation.title}</Header>
          <MainPrice price={price} />
        </TopContainer>
        <MiddleContainer empty />
        <BottomContainer>
          {content.online_consulatation.bullets.map((item, idx) => (
            <ListItem key={idx}>{item}</ListItem>
          ))}
        </BottomContainer>
      </div>
    </MainConatiner>
  );
};

export default PersonalTrainingCard;
