import React from "react";
import { Heading } from "./Heading";

export const SectionTitle = ({ title = "" }) => {
  return (
    <div className="py-20 xl:pb-16 xl:pt-44">
      <Heading size={3}>{title.toUpperCase()}</Heading>
    </div>
  );
};
