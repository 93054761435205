import React from "react";

const PreparationStep = ({ number, title, content }) => {
  return (
    <div className="flex flex-col items-start">
      <span className="inline-block font-semibold text-4xl leading-none tracking-tight bg-highlighted rounded-full p-3">
        {number}
      </span>
      <h3 className="font-bold mt-6 text-2xl lg:text-3xl leading-none">
        {title}
      </h3>
      <p className="inline-block mt-3 text-lg leading-relaxed">{content}</p>
    </div>
  );
};

export default PreparationStep;
