import React from "react";
import { FullWidthContainer, ContentContainer } from "../components/Container";
import content from "../content/sections/about_me.json";
import { StaticImage } from "gatsby-plugin-image";

let baseTitle = content.title.split(" ");
const lastTitleWord = baseTitle.pop();

export default function AboutMeSection() {
  return (
    <FullWidthContainer bgColor="black" id="o-mnie">
      <ContentContainer>
        <div className="pt-16 lg:pt-36 xl:pt-52 flex flex-col-reverse space-y-reverse space-y-12 lg:space-y-0 lg:flex-row items-center lg:space-x-12 xl:space-x-20">
          <StaticImage
            src="../images/practice_1.jpg"
            alt="trening"
            quality={90}
            className="xl:max-w-half"
          />
          <div className="flex flex-col max-w-xl">
            <h2 className="uppercase font-semibold text-3xl lg:text-4xl leading-tight">
              {baseTitle.join(" ")}{" "}
              <span className="border-b-8 border-highlighted">
                {lastTitleWord}
              </span>
            </h2>
            <div className="mt-12 opacity-90 text-xl space-y-8 leading-7 max-w-lg">
              <span>{content.desc1}</span>
              <span className="inline-block mt-8">{content.desc2}</span>
            </div>
          </div>
        </div>
      </ContentContainer>
    </FullWidthContainer>
  );
}
