import React from "react";
import { FullWidthContainer } from "../components/Container";
import { StaticImage } from "gatsby-plugin-image";

export default function FullScreenImageSection() {
  return (
    <FullWidthContainer bgColor="black" id="o-mnie">
      <div className="relative">
        <StaticImage
          src="../images/heavylift.jpg"
          quality={90}
          className="w-full object-cover object-center"
          alt="Author"
        />
        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-60" />
      </div>
    </FullWidthContainer>
  );
}
