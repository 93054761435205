import * as React from "react";
import { Helmet } from "react-helmet";

const title = "Imperium Sprawności | Krzysztof Klempka";
const url = "https://imperium-sprawnosci.pl/";
const description = "Trening medyczny i przygotowanie motoryczne";
const lang = "pl";
const themeColor = "#000000";
const coverImageUrl = "/site-cover.jpg";

export default function SEO() {
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={[
        {
          name: "title",
          content: title,
        },
        {
          name: "description",
          content: description,
        },
        // Open Graph / Facebook
        { name: "theme-color", content: themeColor },
        { name: "og:type", content: "website" },
        { name: "og:url", content: url },
        { name: "og:title", content: title },
        { name: "og:description", content: description },
        { name: "og:image", content: coverImageUrl },
        // Twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:url", content: url },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
        { name: "twitter:image", content: coverImageUrl },
      ]}
    >
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color={themeColor} />
      <script src="https://www.google.com/recaptcha/api.js" async defer />
    </Helmet>
  );
}
