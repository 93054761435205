import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { SmallButton } from "../components/Button";
import { motion, AnimatePresence } from "framer-motion";

export const FizjonersReferal = ({
  url = "https://fizjoners.pl",
  active,
  onClose = () => {},
}) => {
  return (
    <AnimatePresence>
      {active && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed bottom-1 right-1 z-50"
        >
          <div
            className="grid grid-cols-2 max-w-md mt-72 bg-boxGray drop-shadow-md rounded-md relative"
            style={{
              width: "calc(100vw - 2rem)",
            }}
          >
            <button
              className="absolute -top-4 -left-4 z-10 rounded-full h-8 w-8 flex items-center justify-center text-black font-semibold bg-white shadow-md cursor-pointer"
              onClick={onClose}
            >
              x
            </button>
            <div className="h-full">
              <StaticImage
                src="../images/fizjoners.jpg"
                placeholder="blurred"
                className="w-full h-full object-center rounded-l-md"
                alt="Fizjoners"
              />
            </div>
            <div className="h-full flex flex-col p-4">
              <h2 className="text-base md:text-xl uppercase font-semibold">
                Szukasz fizjoterapii? Sprawdź fizjoners.pl
              </h2>
              <p className="mt-3">
                Holistyczne podejście do pacjenta i codzienność bez bólu
              </p>
              <div className="mt-8">
                <SmallButton onClick={() => window.open(url, "_blank")}>
                  sprawdź
                </SmallButton>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
