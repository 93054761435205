export const menuItems = [
  { path: "/", name: "" },
  {
    name: "O mnie",
    path: "#o-mnie",
  },
  {
    name: "Pierwsze spotkanie",
    path: "#pierwsze-spotkanie",
  },
  {
    name: "Metody pracy",
    path: "#metody-pracy",
  },
  {
    name: "Usługi i cennik",
    path: "#uslugi",
  },
  {
    name: "Opinie",
    path: "#opinie",
  },
  {
    name: "Kontakt",
    path: "#kontakt",
    highlighted: true,
  },
];
