import React from "react";
import { Menu } from "../components/Menu";
import Seo from "../components/SEO";
import RulesSection from "../sections/Rules";
import TestimonialsSection from "../sections/Testimonials";
import HeroSection from "../sections/Hero";
import Footer from "../sections/Footer";
import AboutMeSection from "../sections/AboutMe";
import FirstAppointment from "../sections/FirstAppointment";
import ServicesSection from "../sections/Services";
import ContactFormSection from "../sections/ContactForm";
import { Modal as InfoModal } from "../components/Modal";
import FullScreenImageSection from "../sections/FullScreenImage";
import { ToastContainer } from "../components/Toast";

const IndexPage = () => {
  return (
    <>
      <Seo />
      <ToastContainer />
      <InfoModal />
      <Menu />
      <main className="pt-menuMobile md:pt-menuLarge">
        <HeroSection />
        <AboutMeSection />
        <FirstAppointment />
        <ServicesSection />
        <RulesSection />
        <TestimonialsSection />
        <FullScreenImageSection />
        <ContactFormSection />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
