import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import cn from "classnames";

export const MenuItem = ({ name, path, onClickCb, highlighted, mobile }) => {
  return (
    <Link
      to={path}
      className={cn(
        "font-medium cursor-pointer text-center leading-none text-lg",
        {
          "text-highlighted font-bold": highlighted,
          "text-xl uppercase": mobile,
        }
      )}
      onClick={onClickCb}
    >
      {path === "/" ? (
        <StaticImage
          src="../images/logo.png"
          quality={100}
          alt="logo"
          className="w-10 h-10"
          placeholder="none"
        />
      ) : (
        name
      )}
    </Link>
  );
};
