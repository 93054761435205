import { useState, useEffect } from "react";

export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let node;
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });
    if (ref.current) {
      node = ref.current;
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(node);
    };
  }, [ref]);

  return isIntersecting;
}
